import { Button } from "components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useNavigation } from "utils/onNavigate";
import { createVerificationSession } from "services/api";
import config from "config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import {
  extractRoute,
  getOrganizationFromURL,
  LoadPageConfiguration,
} from "utils";
import {
  ALL_FLOWS,
  EQUIFAX_FLOWS,
  VerificationSessionType,
  Workforce,
} from "constant";
import loginFrame from "assets/login/login-main-frame.svg";
import equifaxBanner from "assets/equifaxBanner.png";
import attBanner from "assets/attBanner.png";
import visa from "assets/visa.svg";
import Header from "common/header";
import LivenessSwitch from "../components/livenessSwitch";

let loadded = false;
const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const context = useContext(UserContext);
  const {
    configuration,
    setLoginOption,
    setTokenParams,
    pageConfiguration,
    setPageIndex,
    setPageConfiguration,
    setActiveFlow,
    setPreviousRoutes,
    setIsFaceScan,
    setIsGenerateUuid
  }: any = useContext(UserContext);
  const projectName = getOrganizationFromURL();
  const isChase = projectName === "chase";
  const [flow, setFlow] = useState<any>({});
  const pageConfig: any = pageConfiguration;
  const Options = flow?.flows || ALL_FLOWS;
  const logo = configuration?.logo;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState("");
  const onRegister = async (flow: string) => {
    setLoadingState(flow);
    const payload: any = {
      ...config?.clientConfig,
      productGroupId: configuration?.productGroupId || config?.clientConfig?.productGroupId || "priflo",
    };
    const result: any = await createVerificationSession(payload);
    setTokenParams(result?.token?.replace("?", ""));
    const params = `token=${result?.token?.replace("?", "")}${
      searchParams.get("skipAntispoof")
        ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
        : ""
    }`;
    const url = projectName === "charms" ? '/charm-landing' : '/user-consent'
    if (pageConfig) {
      setLoadingState("");
      navigateToNextPage(params, `${url}`, "/");
    } else {
      setLoadingState("");
      navigate(
        `${url}?token=${result?.token?.replace("?", "")}${
          searchParams.get("skipAntispoof")
            ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
            : ""
        }`
      );
    }
  };

  const onRegisterCharlie = async (flow: string) => {
    setLoadingState(flow);
    const payload: any = {
      ...config?.clientConfig,
      productGroupId: configuration?.productGroupId || config?.clientConfig?.productGroupId || "priflo",
    };
    const result: any = await createVerificationSession(payload);
    setTokenParams(result?.token?.replace("?", ""));
    const params = `token=${result?.token?.replace("?", "")}${
        searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""
    }`;
    if (pageConfig) {
      setLoadingState("");
      navigateToNextPage(params, "/cvs-landing", "/");
    } else {
      setLoadingState("");
      navigate(
        `/cvs-landing?token=${result?.token?.replace("?", "")}${
            searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""
        }`
      );
    }
  };

  const onLogin = async () => {
    setLoadingState(flow);
    const payload: any = {
      ...config?.clientConfig,
      productGroupId: configuration?.productGroupId || config?.clientConfig?.productGroupId || "priflo",
      type: VerificationSessionType.AUTH,
    };
    const result: any = await createVerificationSession(payload);
    setTokenParams(result?.token?.replace("?", ""));
    const params = `token=${result?.token?.replace("?", "")}${
        searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""
    }`;
    if (pageConfig) {
      setLoadingState("");
      navigateToNextPage(params, "/face-login-otp", "/");
    } else {
      setLoadingState("");
      navigate(
        `/face-login-otp?token=${result?.token?.replace("?", "")}${
            searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""
        }`
      );
    }
  };

  useEffect(() => {
    const handleBackButton = () => {
      LoadPageConfiguration(context, "/");
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("afterprint", handleBackButton);
    };
  }, []);

  const onContinuousAuth = async () => {
    localStorage.removeItem("isPinScreen");
    setLoadingState(flow);
    const payload: any = {
      ...config?.clientConfig,
      productGroupId: configuration?.productGroupId || config?.clientConfig?.productGroupId || "priflo",
      type: VerificationSessionType.AUTH,
    };
    const result: any = await createVerificationSession(payload);
    setTokenParams(result?.token?.replace("?", ""));
    const params = `token=${result?.token?.replace("?", "")}${
        searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""
    }`;
    if (pageConfig) {
      setLoadingState("");
      navigateToNextPage(params, "/continuous-authentication-v2", "/");
    } else {
      setLoadingState("");
      navigate(
        `/continuous-authentication-v2?token=${result?.token?.replace(
          "?",
          ""
        )}${searchParams.get("skipAntispoof") ? `&skipAntispoof=${searchParams.get("skipAntispoof")}` : ""}`
      );
    }
  };
  const onContinue = (flow: string) => {
    if (flow === "register") {
      setIsGenerateUuid(false)
      onRegister(flow);
      return;
    } else if (flow === "register-face") {
      setIsGenerateUuid(false)
      setIsFaceScan(true);
      onRegister(flow);
      return;
    } else if (flow === "generate-uuid") {
      setIsGenerateUuid(true);
      navigateToNextPage('', "/user-consent", "/");
      return;
    } else if (flow === "register-i9") {
      setIsGenerateUuid(false)
      onRegisterCharlie(flow);
      return;
    } else if (flow === "checkoutVc") {
      setIsGenerateUuid(false)
      navigateWithQueryParams("/checkout");
    } else if (flow === "face-login-otp") {
      setIsGenerateUuid(false)
      onLogin();
    } else if (flow === "continuous_authentication") {
      setIsGenerateUuid(false)
      if (projectName === "charlie") {
        onContinuousAuth();
      } else {
        navigateWithQueryParams("/continuous-authentication");
      }
    } else if (flow === "predictAge") {
      setIsGenerateUuid(false)
      setLoginOption(flow);
      onRegister(flow);
    } else {
      setIsGenerateUuid(false)
      setLoginOption(flow);
      if (pageConfig) {
        navigateToNextPage("", "/face-login", "/");
      } else {
        navigateWithQueryParams("/face-login");
      }
    }
  };

  useEffect(() => {
    if (projectName === 'charms') {
      onContinue("register")
    }
  }, [projectName])

  const renderBannerImage = () => {
    switch (projectName) {
      case "att":
        return attBanner;
      case "equal":
        return equifaxBanner;
      case "cache":
        return visa;
      default:
        return configuration?.bannerImage || loginFrame;
    }
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === window.location.pathname
    );
    const activeIndex = currentRouteIndex;
    setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(configuration, activeIndex);
    setPageConfiguration(pageConfiguration);
  };

  useEffect(() => {
    if (!loadded) {
      loadded = true;
      loadPageConfiguration();
    }
  }, [configuration, loadded]);

  const onChangeFlow = (value: string) => {
    setActiveFlow(value);
    setPreviousRoutes([]);
    localStorage.setItem("equifaxActiveFlow", value);
    const options = pageConfig?.options || ALL_FLOWS;
    const flows = options?.filter((flow: any) => flow?.type === value);
    const bannerHeading = value === EQUIFAX_FLOWS[1] ? "Verified Consumer" : "";
    setFlow({
      flows: flows?.length > 0 ? flows : Workforce,
      bannerHeading,
    });
  };
  useEffect(() => {
    onChangeFlow(localStorage.getItem("equifaxActiveFlow") || EQUIFAX_FLOWS[0]);
  }, [pageConfig]);

  if (projectName === 'charms') return null
  return (
    <div
      className={`bg-[url('https://equifax.privateid.co/static/media/equifaxBG.3d8cdb8d8f05eff93313dc72c275c6c3.svg')] bg-[100% 100%] bg-center bg-no-repeat ${
        configuration?.bgColor ? "h-[100vh] max-md:h-[calc(100vh_0px)]" : ""
      }`}
      style={{ backgroundColor: configuration?.bgColor }}
    >
      {/* Header */}
      <Header onChangeFlow={onChangeFlow} />
      {/* Header End */}
      {/* Banner */}
      <div className="flex h-[80vh] items-center container max-md:p-[10px]">
        {/* Left wrap */}
        <div className="flex-1">
          <div className="w-[400px] max-md:w-[100%] max-md:mt-[7rem]">
            {!isChase && (
              <img
                src={logo}
                alt=""
                data-src={logo}
                className={`lazyload ${
                  configuration?.bannerWidth
                    ? `w-[${configuration?.bannerWidth}]`
                    : "w-[150px]"
                }  m-auto`}
              />
            )}
            <p
              className={`text-[24px] mb-[5px] text-[#837d7d] text-center ${
                (isChase || configuration?.isKantara) && "text-center"
              }`}
            >
              {flow?.bannerHeading ||
                pageConfig?.title ||
                "Verified Private Identity"}
            </p>
            {configuration?.isKantara && (
              <p className="mt-[-5px] text-[18px] mb-[20px] text-center font-[400] text-secondaryText max-md:text-[14px] max-md:w-[80%] max-md:m-auto max-md:mt-[7px]">
                A simple, fast and safe way to verify yourself.
              </p>
            )}
            <div>
              {Options?.map(
                (
                  item: { title: string; value: string; enable: boolean },
                  index: number
                ) => {
                  if (!item?.enable) return null;
                  return (
                    <Button
                      key={index}
                      className="w-[100%] justify-start h-[70px] bg-primary font-[400] rounded-[10px] text-[20px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%] mb-[10px] hover:underline"
                      onClick={() => onContinue(item?.value)}
                    >
                      {loadingState === item?.value ? (
                        <Loader2 className="animate-spin h-[55px] w-[55px] text-[#fff] m-auto" />
                      ) : (
                        item?.title
                      )}
                    </Button>
                  );
                }
              )}
            </div>
          </div>
        </div>
        {/* Left wrap end */}
        {/* Right wrap */}
        <div className="flex-1 flex justify-center max-md:hidden">
          <div className="w-[500px]">
            <img
              src={renderBannerImage()}
              alt=""
              data-src={renderBannerImage()}
              className={`lazyload w-[80%] m-auto`}
            />
          </div>
        </div>
        {/* Right wrap */}
      </div>
      {/* Banner end */}
      <LivenessSwitch liveness={configuration?.liveness} />
    </div>
  );
};

export default LandingPage;
