import { Button } from "components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useNavigation } from "utils/onNavigate";
import MainLogo from "../../assets/login/logo-main.png";
import { getOrganizationFromURL } from "utils";
import { EQUIFAX_FLOWS } from "constant";
import { ChevronDown } from "lucide-react";
import {
  dropGenerateRegistrationOptions,
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { useToast } from "components/ui/use-toast";
import { startRegistration } from "@simplewebauthn/browser";
import {deleteUser} from "@privateid/cryptonets-web-sdk";

const Header = ({ onChangeFlow }: any) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const {
    configuration,
    setLoginOption,
    pageConfiguration,
    activeFlow,
    setPreviousRoutes,
  }: any = useContext(UserContext);
  const projectName = getOrganizationFromURL();
  const isChase = projectName === "chase";
  const pageConfig: any = pageConfiguration;
  const logo = configuration?.logo || MainLogo;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { toast } = useToast();
  const [isMenu, setIsMenu] = useState(false);
  const onSignin = () => {
    setLoginOption("faceLogin");
    setPreviousRoutes([]);
    if (pageConfig) {
      navigateToNextPage("", "/face-login", "/");
    } else {
      navigateWithQueryParams("/face-login");
    }
  };

  const toggelMenu = () => {
    setIsMenu(!isMenu);
  };

  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    toggelMenu();
  };

  const onGeneratePasskey = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "There was some issue generating with passkey.",
      });
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "There was some issue generating with passkey.",
      });
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      toast({
        variant: "success",
        description: "Passkey successfully generated.",
      });
    }
  };

  const dropPasskey = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    // try {
    //   response = await dropGenerateRegistrationOptions(uuid);
    // } catch (error: any) {
    //   toast({
    //     variant: "destructive",
    //     description: "Failed to generate passkey",
    //   });
    //   navigateToNextPage("", "/", "/");
    //   return;
    // }
    let attResp;
    try {
      const opts = response;
      // attResp = await startRegistration(opts);
    } catch (error: any) {}
    const verificationJSON = await verifyRegistration({ attResp, uuid: uuid });
    if (verificationJSON?.verified) {
      toast({
        variant: "destructive",
        description: "Passkey Successfully Droped",
      });
      navigateToNextPage("", "/", "/");
    }
  };

  const onDeleteUser = () => {
    onLogout();
    toast({
      variant: "success",
      description: "User Deleted Successfully",
    });
  }

  const handleDeleteUser = async () => {
    toggelMenu();
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    try {
      // deleteUser(uuid, onDeleteUser);
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "Failed to delete user",
      });
      navigateToNextPage("", "/", "/");
      return;
    }
  };

  return (
    <div className="flex px-[24px] py-[20px] max-md:p-[10px] max-md:py-[20px] items-center">
      <img
        src={isChase ? logo : MainLogo}
        alt=""
        data-src={isChase ? logo : MainLogo}
        className={`lazyload w-[160px] max-md:w-[110px]`}
      />
      <div className="flex flex-1 justify-end items-center">
        {configuration?.consumerButtons &&
          EQUIFAX_FLOWS?.map((flow) => {
            const shadow =
              activeFlow === flow
                ? "!shadow-[inset_0_4px_7px_0px_rgba(0,0,0,0.3)]"
                : "";
            return (
              <Button
                onClick={() => onChangeFlow(flow)}
                className={`w-[130px] ${shadow}  border-primary border border-solid text-primary bg-transparent rounded-[24px]  text-[14px] hover:opacity-90 max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] mr-[10px]`}
              >
                {flow}
              </Button>
            );
          })}
        {user?.user || user?.uuid ? (
          <>
            <div className="relative">
              <div className="flex items-center" onClick={toggelMenu}>
                <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mt-[-5px] cursor-pointer z-50">
                  <svg
                    className="absolute w-10 h-10 text-gray-400 -left-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <ChevronDown
                  className={`cursor-pointer w-[20px] ml-[5px] ${
                    isMenu && "rotate-180"
                  } transition-all`}
                />
              </div>
              {isMenu && (
                <>
                  <div
                    id="userDropdown"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 overflow-hidden absolute right-[-40px] top-[50px]"
                  >
                    <ul
                        className="text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="avatarButton"
                    >
                      {/* <li
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                        onClick={() => {}}
                      >
                        Delete account
                      </li> */}
                      <li
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                          onClick={onGeneratePasskey}
                      >
                        Generate passkey
                      </li>
                      <li
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                          onClick={dropPasskey}
                      >
                        Drop passkey
                      </li>
                      <li
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                          onClick={handleDeleteUser}
                      >
                        Delete User
                      </li>
                    </ul>
                  </div>
                  <div
                      className="fixed left-0 top-0 w-full h-full"
                      onClick={toggelMenu}
                  />
                </>
              )}
            </div>
            <Button
                className="w-[130px] bg-transparent border-2 border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-primary hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
              onClick={onLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <Button
            className="w-[130px] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px]"
            onClick={onSignin}
          >
            Sign in
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
