import InputMask from "react-input-mask";
import { Input } from "../../../components/ui/input";
import demoFlag from "../../../assets/login/demo-flag.svg";

interface PhoneInputProps {
  setValue: (phone: string) => void;
  handleFocus?: () => void;
  errorMessage?: string;
  placeholder?: string;
  ariaLabel?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  setValue,
  handleFocus,
  errorMessage,
  placeholder,
  ariaLabel,
}) => {
    const beforeMaskedStateChange = ({ nextState }: any) => {
        let newState = nextState;
        let value = nextState?.enteredString;
        if (value?.length > 8 && value?.charAt(0) === '0') {
            value = value.slice(1);
            newState = { ...newState, value };
        }

        return newState;
    };
  return (
    <div className="relative">
      <InputMask
        mask="999-999-9999"
        name={"phone"}
        maskPlaceholder={null}
        alwaysShowMask={true}
        beforeMaskedStateChange={beforeMaskedStateChange}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        onFocus={handleFocus}
      >
        <Input
          type="tel"
          name="phone"
          autoComplete={"tel"}
          placeholder={placeholder || "Enter your phone number *"}
          className="mt-5 ps-[3.3rem] h-[56px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal"
          maxLength={15}
          error={errorMessage}
          aria-label={ariaLabel}
        />
      </InputMask>
      <div className="flex absolute top-4 left-2">
        <img
          src={demoFlag}
          alt="country-icon"
          className="font-[400] text-primaryText"
        />
        <span className="ml-[3px] text-[0.875rem] mt-[1.7px]">+1</span>
      </div>
    </div>
  );
};

export default PhoneInput;
