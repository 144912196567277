import { convertCroppedImage, enroll } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

let skipAntispoofProcess = false;
let identifierGlobal: any = undefined;
let collectionNameGlobal: any = undefined;

const useEnroll = (onSuccess: () => void) => {
  const [enrollStatus, setEnrollStatus] = useState("");
  const [searchParams] = useSearchParams();
  const [enrollValidationStatus, setEnrollValidationStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [enrollGUID, setEnrollGUID] = useState("");
  const [enrollPUID, setEnrollPUID] = useState("");
  const [enrollImageData, setEnrollImageData] = useState<any>("");

  let enrollTokenCurrent;
  const callback = async (result: any) => {
    console.log("enroll callback hook result:", result);
    console.log("skipping antispoof?", skipAntispoofProcess);
    const token = result.mf_token;
    if (token) {
      setProgress((p) => {
        if (p >= 100) return 100;
        return p + 20;
      });
    } else {
      setProgress(0);
    }
    if (result.enroll_performed) {
      setEnrollGUID(result.guid);
      setEnrollPUID(result.puid);
      setProgress(100);
      setEnrollValidationStatus("Success");
      onSuccess();
    } else {
      setEnrollValidationStatus(getStatusMessage(result.face_validation_status));
      setEnrollStatus(result.face_validation_status);
      enrollUserOneFa(result.mf_token, skipAntispoofProcess, collectionNameGlobal, identifierGlobal);
    }
  };

  const enrollUserOneFa = async (
    token = "",
    skipAntispoof = false,
    collectionName = '',
    identifier = ''
  ) => {
    enrollTokenCurrent = token;
    skipAntispoofProcess = skipAntispoof;
    collectionNameGlobal = collectionName;
    identifierGlobal = identifier;
    // eslint-disable-next-line no-unused-vars
    const bestImage: any = await enroll({
      callback: callback,
      config: {
        input_image_format: "rgba",
        mf_token: token,
        skip_antispoof:
          searchParams.get("skipAntispoof") === null
            ? true
            : searchParams.get("skipAntispoof") === "true" || skipAntispoof,
      },
    });
    if (bestImage) {
      setEnrollImageData(new ImageData(bestImage.imageData, bestImage.width, bestImage.height));
      const bestImagePortrait = await convertCroppedImage(bestImage.imageData, bestImage.width, bestImage.height);
      console.log("enroll image:", bestImagePortrait);
    }
  };

  return {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress
  };
};

export default useEnroll;
