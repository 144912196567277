import { AUTH, FLOW, REQUIRES_INPUT, SUCCESS } from "../../constant";
import { UserContext } from "../../context/userContext";
import { getUser, getUserPortrait, verifyTokenApi } from "../../services/api";
import {
  convertLinkToImageData,
  extractRoute,
  getOrganizationFromURL,
  getStatusFromUser,
  LoadPageConfiguration,
} from "../../utils";
import { useContext, useEffect } from "react";
import MainLogo from "../../assets/login/logo-main.png";
import HhsLogo from "../../assets/hhsMainLogo.svg";
import { useSearchParams } from "react-router-dom";
import useWasm from "hooks/useWasm";
import Footer from "common/components/footer";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";
import { UAParser } from 'ua-parser-js';

type Props = {
  children?: any;
  removeBorder?: boolean;
  removeHeight?: boolean;
  backgroundColor?: string;
  logo?: string;
  heightOverride?: boolean | any;
  shadow?: boolean;
  hideFooter?: boolean;
};
let loadded = false;
let pageLoadded = false;
const Layout = (props: Props) => {
  const [searchParams] = useSearchParams();
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari"
  const {
    removeBorder,
    removeHeight = false,
    backgroundColor,
    logo,
    heightOverride = false,
    shadow,
    hideFooter = false,
  } = props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const context = useContext(UserContext);
  const {
    setUser,
    user,
    setTokenParams,
    themeHhs,
    setSuccessMessage,
    configuration,
    setPageConfiguration,
    pageIndex,
    setPageIndex,
  }: { configuration: any } | any = useContext(UserContext);
  const { ready: wasmReady, wasmStatus } = useWasm();
  const token = searchParams.get("token");
  const projectName = getOrganizationFromURL();
  const verifyTokenAPI = async (token: any) => {
    setTokenParams(token?.replace("?", ""));
    await verifyTokenApi(token).then(handleTokenVerification);
  };

  const handleTokenVerification = async (res: any) => {
    if (res?.user && res?.type !== AUTH) {
      await handleCustomerVerification(res);
    }
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (window.location.pathname !== "/") {
        LoadPageConfiguration(context, window.location.pathname);
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("afterprint", handleBackButton);
    };
  }, []);

  const loadPageConfiguration = (route?: string) => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === (route || window.location.pathname)
    );
    const activeIndex = route === "/" ? 0 : currentRouteIndex || pageIndex;
    setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(configuration, activeIndex);
    setPageConfiguration(pageConfiguration);
  };

  useEffect(() => {
    if (!loadded) {
      loadded = true;
      loadPageConfiguration();
    }
  }, [configuration, loadded]);

  const handleCustomerVerification = async (res: any) => {
    setUser({ ...user, userId: res.user });
    const isCompleted = (element: number) =>
      res.flowsCompleted.includes(element);
    try {
      if (!res.flowsCompleted.length) {
        loadPageConfiguration();
        navigateWithQueryParams("/", { token: undefined });
      } else if (
        res.flowsCompleted.length === 1 &&
        isCompleted(FLOW.CREATE_USER)
      ) {
        if (!configuration?.isKantara) {
          navigateToNextPage(
            `token=${res?.token}`,
            `/face-scan`,
            "/switch-device"
          );
        }
      } else if (
        res.flowsCompleted.length === 2 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE].every(isCompleted)
      ) {
        const userPortrait: any = await getUserPortrait(res.token);
        if (userPortrait?.data) {
          try {
            navigateToNextPage(
              `token=${res?.token}`,
              `/drivers-licence-intro`,
              "/switch-device"
            );
            const enrollImageData = await convertLinkToImageData(
              userPortrait.data
            );
            setUser({ ...user, enrollImageData });
          } catch (error) {
            console.error(error);
          }
        } else {
          navigateToNextPage(
            `token=${res?.token}`,
            `/failed`,
            "/switch-device"
          );
        }
      } else if (
        res.flowsCompleted.length === 3 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE, FLOW.UPLOAD_DOC_FRONT].every(
          isCompleted
        )
      ) {
        navigateToNextPage(
          `token=${res?.token}`,
          `/drivers-licence-back-intro`,
          "/switch-device"
        );
      } else {
        const data: any = await getUser({ id: res.user });
        const { userApproved, requestScanID, requestResAddress, ...rest } =
          data.status || {};

        setUser({
          ...user,
          userStatus: {
            userApproved,
            requestScanID,
            requestResAddress: !requestScanID && requestResAddress,
            ...rest,
          },
        });
        handleUserStatus(userApproved, rest, res);
      }
    } catch (e) {
      loadPageConfiguration();
      navigateWithQueryParams("/", { token: undefined });
    }
  };

  const handleUserStatus = (userApproved: any, rest: any, res: any) => {
    const status = getStatusFromUser({ userApproved, ...rest });

    if (status === SUCCESS) {
      setSuccessMessage("Success! Your account is created");
      navigateToNextPage(`token=${res?.token}`, `/success`, "/switch-device");
    } else if (status === REQUIRES_INPUT) {
      navigateWithQueryParams(`/address`, { token: res?.token });
    } else {
      navigateWithQueryParams(`/failed`, { token: res?.token });
    }
  };
  useEffect(() => {
    if (!token) return;
    if (!pageLoadded) {
      verifyTokenAPI(token);
      pageLoadded = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageLoadded]);

  const logoImage = logo
    ? logo
    : configuration?.logo
    ? configuration?.logo
    : themeHhs
    ? HhsLogo
    : MainLogo;

  return (
    <>
      <div
         className={`flex text-center justify-center main-layout ${
           configuration?.bgColor && "h-[100vh]"
         } max-md:h-[calc(100vh_-_4rem)] ${
           window.innerWidth < 767
             ? isMobileSafari
               ? "[@media_((min-height:700px)_and_(max-height:800px))]:h-[calc(100vh_-_9rem)]"
               : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_7rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:800px))]:h-[calc(100vh_-_7rem)]"
             : ""
         }  min-[1600px]:h-[${configuration?.bgColor ? "100vh" : "95vh"}]`}
        style={{
          height: heightOverride && "unset",
          backgroundColor: configuration?.bgColor,
        }}
      >
        <div
          className={`py-8 max-w-[600px] w-[100%] ${
            !removeBorder && "max-md:py-0"
          }`}
        >
          <div
            className={`flex justify-center ${
              themeHhs ? "h-[70px]" : "h-[34px]"
            } ${!removeBorder && "max-md:hidden"}`}
          >
            <img
              src={logoImage}
              alt=""
              data-src={logoImage}
              className={`lazyload cursor-pointer`}
              onClick={() => {
                loadPageConfiguration("/");
                navigateWithQueryParams("/", { token: undefined });
              }}
            />
          </div>
          <div
            className={`inner-layout relative ${!removeHeight && `h-[650px]`} ${
              themeHhs ? "mt-6" : "mt-10  max-md:mt-1"
            } w-full ${
              !removeBorder && `border border-border max-md:border-none`
            }  rounded-[24px] max-md:h-[100%] ${
              shadow && !removeBorder && "shadow-lg"
            }`}
            style={{
              backgroundColor: configuration?.bgColor
                ? "#fff"
                : backgroundColor,
            }}
          >
            {props?.children}
          </div>
        </div>
      </div>
      {!hideFooter && <Footer />}
    </>
  );
};

export default Layout;
