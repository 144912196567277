import { useState, useEffect, useContext } from "react";
import { loadPrivIdModule } from "@privateid/cryptonets-web-sdk";
import { getUrlParameter } from "../utils";
import { UserContext } from "context/userContext";

let isLoading = false;
const useWasm = () => {
  // Initialize the state
  const [ready, setReady] = useState(false);
  const [wasmStatus, setWasmStatus] = useState<any>({ isChecking: true });
  const context: any = useContext(UserContext);
  const { isWasmLoaded, setIsWasmLoaded } = context;
  const init = async () => {
    const default_api_key =
      process.env.REACT_APP_API_KEY || context?.configuration?.apiKey;
    const default_url =
      process.env.REACT_APP_API_URL || context?.configuration?.cryptonetsUrl;
    const apiKey = getUrlParameter("api_key", default_api_key) || "";
    const apiUrl = getUrlParameter("api_url", default_url) || "";
    const orchestrationUrl =
      context?.configuration?.orchestrationUrl ||
      context?.configuration?.env?.REACT_APP_API_ORCHESTRATION ||
      process.env.REACT_APP_ORCHESTRATION_API_URL;
    const isSupported = await loadPrivIdModule({
      api_url: {
        collections: {
          default: {
            named_urls: {
              base_url: apiUrl,
              enroll: "https://api.develv3.cryptonets.ai/node/FACE4_2/enroll",
              predict: "https://api.develv3.cryptonets.ai/node/FACE4_2/predict",
              deleteUser:
                "https://api.develv3.cryptonets.ai/node/FACE4_2/deleteUser",
              syncUUID:
                "https://api.develv3.cryptonets.ai/node/FACE4_2/syncUUID",
            } as any,
          },
        },
      },
      api_key: apiKey,
      api_orchestration_url: orchestrationUrl,
    });
    if (isSupported.support) {
      setReady(true);
      setWasmStatus({ isChecking: false, ...isSupported });
      setIsWasmLoaded(true);
    } else {
      setReady(false);
      setWasmStatus({ isChecking: false, ...isSupported });
    }
  };

  useEffect(() => {
    if (ready) return;

    if (!isWasmLoaded && !isLoading) {
      init();
      isLoading = true;
    }
    if (isWasmLoaded) {
      setReady(true);
      setWasmStatus({ isChecking: false, support: true });
    }
  }, [isWasmLoaded]);

  return { ready, wasmStatus };
};

export default useWasm;
