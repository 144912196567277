import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import PinInput from "react-pin-input";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext, useState } from "react";
import { isMobile } from "utils";
import { UserContext } from "context/userContext";
import Stepper from "common/faceScanningIntro/Stepper";
import { useNavigation } from "utils/onNavigate";

type Props = {};

const VerifyPin = (props: Props) => {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const [pin, setPin] = useState("");
  const [wrongPin, setWrongPin] = useState(false);
  const handlePinOnComplete = (value: string) => {
    setPin(value);
  };
  const isLogin = context?.loginOption === "loginPin";
  return (
    <Layout>
      <form autoComplete={"on"} style={{ height: "100%" }}>
        <div className="px-10 py-8 max-md:p-[20px]">
          <div className="flex justify-between relative">
            <BackButton />
            {isLogin && (
              <div className="flex items-center justify-center w-full">
                <Stepper step={1} loginSteps={true} />
              </div>
            )}
            <CloseButton />
          </div>
          <div className="mt-2">
            <Label className="text-[28px] font-[500] text-primaryText">
              {pageConfig?.title
                ? pageConfig?.title
                : isLogin
                ? "Enter your existing PIN to continue"
                : "Enter your Card PIN"}
            </Label>
          </div>
          <div className="text-center overflow-auto h-[490px] mt-3 p-2">
            <div className="ps-8 pe-8">
              <Label className="text-[14px] font-[400] text-secondaryText">
                {pageConfig?.subheading
                  ? pageConfig?.subheading
                  : isLogin
                  ? "This PIN provides an additional layer of security"
                  : "Enter your PIN to Register"}
              </Label>
            </div>
            <div className="">
              <div className="relative">
                <PinInput
                  length={4}
                  initialValue=""
                  secret
                  focus
                  secretDelay={100}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  ariaLabel={`pin`}
                  inputStyle={{
                    borderColor: "gray",
                    width: isMobile ? "50px" : "70px",
                    height: isMobile ? "50px" : "70px",
                    fontSize: isMobile ? "20px" : "24px",
                    marginLeft: 10,
                    transition: "all .2s",
                  }}
                  inputFocusStyle={{
                    borderColor: "#5283EC",
                    transform: "scale(1.1)",
                  }}
                  onChange={(e) => {
                    setWrongPin(false);
                  }}
                  onComplete={(value) => {
                    handlePinOnComplete(value);
                  }}
                  autoSelect={true}
                  regexCriteria={/^[0-9]*$/}
                />
              </div>
              {wrongPin && (
                <div className="ps-8 pe-8">
                  <Label className="text-[14px] font-[400] text-[red]">
                    {pageConfig?.errorMessage ||
                      "Whoops, wrong PIN. Please try again."}
                  </Label>
                </div>
              )}
            </div>
            {isLogin && (
              <div className="ps-8 pe-8">
                <Label
                  className="text-[14px] font-[400] text-primary cursor-pointer hover:underline"
                  onClick={() => {
                    if (pageConfig) {
                      navigateToNextPage("", "/", "/verify-pin");
                    } else {
                      navigateWithQueryParams("/login-options");
                    }
                  }}
                >
                  {pageConfig?.forgotMessage || "Forgot PIN"}
                </Label>
              </div>
            )}

            <div
              className={`text-left h-[100px] absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
            >
              <div>
                <Button
                  className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                  disabled={!pin}
                  onClick={() => {
                    if (pageConfig?.type === "register") {
                      navigateToNextPage("", "/waiting", "/verify-pin");
                    } else 
                    if (pageConfig) {
                      navigateToNextPage("", "/success", "/verify-pin");
                    } else {
                      navigateWithQueryParams("/success");
                    }
                  }}
                >
                  {pageConfig?.buttonText || "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default VerifyPin;
